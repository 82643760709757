import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from 'src/app/modulos/shared/services/autenticacao.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private autenticacaoService: AutenticacaoService,) { }

  ngOnInit() {
  }

  sair() {
    this.autenticacaoService.logout();
  }

}
