import { ToastrService } from 'ngx-toastr';
import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { TextMaskModule } from 'angular2-text-mask';
import { LoadingScreenService } from './components/loading-screen/loading-screen.service';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './components/loading-screen/loading-screen.interceptor';
import { NoAuthGuardRouterService } from './guards-routers/no-auth-guard-router.service';


@NgModule({
  declarations: [LoadingScreenComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,    
  ],
  exports:[
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,
    LoadingScreenComponent,
  ],
  providers:[
    ToastrService,
    NoAuthGuardRouterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    LoadingScreenService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [       
        
      ]
    };
  }
 }
