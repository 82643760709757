//import { Corretor } from './../../modules/cadastro-corretor/corretor';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Usuario } from '../classes/usuario';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, catchError } from 'rxjs/operators';

import * as moment from 'moment';
import 'moment/locale/pt-br';
//import { Profissao } from 'src/app/services/profissao/profissao';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStorageService } from './local-storage.service';
import { CookieStorageService } from './cookie-storate.service';
import { ResultDTO } from '../../renegociacao/pages/home/service/DTO/response/result.DTO';
//import { ProfissaoService } from 'src/app/services/profissao/profissao.service';

moment.locale('pt-BR');

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {
  static readonly keyLocalStorageUsuario = 'usuario';
  private host = environment.host;
  private usuario: Usuario;
  usuarioStatus: Subject<any> = new Subject();

  get token() {
    return this.cookieStorageService.getObject('token');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorage: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    //private permissionsService: NgxPermissionsService,
    //private profissaoService: ProfissaoService
  ) {
    // this.usuario = new Usuario();
    // this.usuario.map(
    //   this.localStorage.getObject(AutenticacaoService.keyLocalStorageUsuario)
    // );



    //this.carregarPermissoes();
  }

  // setUsuario(dados) {
  //   this.usuario.map(dados);

  //   this.localStorage.setObject(
  //     AutenticacaoService.keyLocalStorageUsuario,
  //     dados
  //   );
  //   this.usuario.map(
  //     this.localStorage.getObject(AutenticacaoService.keyLocalStorageUsuario)
  //   );
  //   return of(this.usuario);
  // }

  // getUsuario() {
  //   return this.usuario;
  // }

  // getCorretorLogado() {
  //   let corretor = new Corretor();

  //   if (this.usuario.corretor) {
  //     corretor.map(this.usuario.corretor);      
  //   } else {
  //     corretor.map(this.usuario);      
  //   }

  //   return corretor;
  // }

  login(data: Usuario): Observable<ResultDTO> {
    return this.http.post<ResultDTO>(
      `${this.host}/api/auth/login`,
      data).pipe(
              tap((dadosDeAcesso: any) => {
                this.salvarDadosDeAcesso(dadosDeAcesso);
              })
              // catchError(ErrorResponse.get)
            );
  }

  // carregarPermissoes() {    
  //   if (this.token != null && this.token['asset'] != null) {
  //     // this.permissionsService.flushPermissions();
  //     // this.permissionsService.loadPermissions([this.token['asset']]);

  //   }
  // }

 

  salvarDadosDeAcesso(dadosDeAcesso) {
    console.log("dadosAcesso", dadosDeAcesso);
    if (dadosDeAcesso) {
      console.log("entrei dados de acesso");
      if (dadosDeAcesso['cpf']) {
        console.log("entrei cpf");
        this.localStorage.setObject(
          AutenticacaoService.keyLocalStorageUsuario,
          dadosDeAcesso['cpf']
        );
      }
      if (dadosDeAcesso['cpf']) {
        console.log("entrei no token");
        this.cookieStorageService.setObject('token', dadosDeAcesso['cpf']);
        //this.carregarPermissoes();
      }

      // this.usuario = new Usuario();
      // this.usuario.map(
      //   this.localStorage.getObject(AutenticacaoService.keyLocalStorageUsuario)
      // );
      // this.usuarioStatus.next(this.usuario);
    }
  }

  isAuthorized() {
    //this.carregarPermissoes();
    console.log("this.token", this.token);
    if (this.token) {
      const hoje = moment();
      hoje.milliseconds(0);
      hoje.seconds(0);
      hoje.minutes(0);
      hoje.hours(0);

      const dataExpiracao = moment(this.token['dataExpiracao'], 'DD/MM/YYYY');

      dataExpiracao.milliseconds(0);
      dataExpiracao.seconds(0);
      dataExpiracao.minutes(0);
      dataExpiracao.hours(0);

      return hoje.valueOf() <= dataExpiracao.valueOf();
    }else{
      this.router.navigate(['/renegociacao/home']);
      return true;
    }
    return false;
  }

  redirecionarPaginaInicial() {
    console.log("redirecionarPagina");
    if (
      this.token != null
    ) {
      //this.router.navigate(['/renegociacao/home']);
    }  else {
      //this.router.navigate(['/renegociacao/home']);
    }
  }


  logouSemRedirect(){
    this.cookieStorageService.clear();
    this.localStorage.clear();
  }

  logout() {
    let urlRedirect = "";
    if (
      this.token != null
      && this.token['asset'] != null
      && this.token['asset'] === 'Corretor'
    ) {
      urlRedirect = '';
    } 

    this.logouSemRedirect();
    //this.profissaoService.clear();

    // this.usuario = new Usuario();
    // this.usuarioStatus.next(this.usuario);

    // this.permissionsService.flushPermissions();
    this.router.navigate([urlRedirect]);
  }

  isCorretor(){
    if (
      this.token != null
      && this.token['asset'] != null
      && this.token['asset'] === 'Corretor'           
    ){
      return true;      
    }
    
    return false;
  }

}
