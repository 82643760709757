import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieStorageService {
    constructor() { }
    set(key, value, exdays?) {
        let expires = '0';
        if (exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            expires = 'expires=' + d.toUTCString();
        }

        document.cookie = key + '=' + value + ';' + expires + ';path=/';

        return this;
    }

    get(key, defaultValue = null) {
        const name = key + '=';
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return defaultValue;
    }

    setObject(key, value: Object) {
        this.set(key, JSON.stringify(value));
        // window.sessionStorage[key] = JSON.stringify(value);
        return this;
    }

    getObject(key) {
        var item = this.get(key);
        if(item == null) return null;
        return JSON.parse(item);
    }

    remove(key) {
        // window.sessionStorage.removeItem(key);
        // return this;
    }

    clear() {
        const cookies = document.cookie.split('; ');

        for (let cookie of cookies) {
            const index = cookie.indexOf('=');

            const name = ~index
                ? cookie.substr(0, index)
                : cookie;

            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }
}
