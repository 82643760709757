import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { AutenticacaoService } from '../services/autenticacao.service';



@Injectable()
export class NoAuthGuardRouterService implements CanActivate {
    constructor(private auth: AutenticacaoService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        console.log("auth");
        if (!this.auth.isAuthorized()) {
            console.log("autorizado")
            return true;
        }

        this.auth.redirecionarPaginaInicial();
        console.log("redirecionar página inicial");
        return false;
    }
}
