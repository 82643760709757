import { ToastrModule } from 'ngx-toastr';


import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modulos/shared/shared.module';
import { LayoutDeslogadoComponent } from './layouts/layout-deslogado/layout-deslogado.component';
import { LayoutLogadoComponent } from './layouts/layout-logado/layout-logado.component';
import { HeaderComponent } from './layouts/header/header.component';
import { CopyrightComponent } from './layouts/copyright/copyright.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';


@NgModule({
  declarations: [
    AppComponent,
    LayoutDeslogadoComponent, LayoutLogadoComponent, HeaderComponent, CopyrightComponent
  ],
  imports: [
    BrowserModule,     
    AppRoutingModule,  
    RouterModule,
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    NgxLoadingModule . forRoot ( {
        animationType : ngxLoadingAnimationTypes . wanderingCubes  ,
        backdropBackgroundColour : ' rgba (0,0,0,0.1) ', 
        backdropBorderRadius : ' 4px ' ,
        primaryColour : ' #ffffff ' , 
        terciaryColour : ' #ffffff ' 
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 30000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
      enableHtml: true,
      progressBar: true,
      closeButton: true
    }),
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
